import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  adminUpdateItemReviewEntry,
  getItemsReviewEntriesForRevision,
  refreshView,
} from "../../../actions/inventoryCrossActions/InventoryCrossActions"
import { RequestsItem } from "../../../actions/inventoryCrossActions/InventoryCrossActionsTypes"
import { getUsers } from "../../../actions/userActions/UserActions"
import { RootStore } from "../../../Store"
import Pagination from "../utils/ResultsPagination"
import useStyles from "./InventoryCrossCheckManagementsStyles"

const InventoryCrossCheckManagements = () => {
  const classes = useStyles()
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const { userList } = useSelector((state: RootStore) => state.user)
  const { itemsForReview, refresh, loading, actionStatus } = useSelector(
    (state: RootStore) => state.inventoryCross
  )
  const totalPages = itemsForReview?.data.totalPages ?? 0
  const [currentPage, setCurrentPage] = useState<number>(
    itemsForReview?.data.pageable.pageNumber ?? 0
  )
  const [sellerId, setSellerId] = useState<string>("")
  const [searchTerm, setSearchTerm] = useState<string>("")

  const params = new URLSearchParams(window.location.search)
  let page = params.get("page")
  const location = useLocation()

  const handleClearFilters = () => {
    setSellerId("")
    setSearchTerm("")
    window.history.replaceState(null, "", location.pathname)
  }

  const onSearch = () => {
    dispatch(
      getItemsReviewEntriesForRevision({
        sellerId: sellerId,
        searchTerm: searchTerm,
        paged: true,
        size: 10,
        page: 0,
      })
    )
    window.history.replaceState(null, "", location.pathname)
  }

  const handleChangeStatus = (id: number, approved: boolean) => {
    dispatch(adminUpdateItemReviewEntry({ id: id, isApproved: approved }))
  }

  useEffect(() => {
    dispatch(getUsers({ role: "ROLE_SELLER" }))
    dispatch(
      getItemsReviewEntriesForRevision({
        paged: true,
        size: 10,
        page: page ? parseInt(page) - 1 : 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(
      getItemsReviewEntriesForRevision({
        sellerId: sellerId,
        searchTerm: searchTerm,
        paged: true,
        size: 10,
        page: page ? parseInt(page) - 1 : 0,
      })
    )
    dispatch(refreshView(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, refresh])

  useEffect(() => {
      if (actionStatus !== undefined) {
        if (actionStatus.menssage === "status.success-update") {
          dispatch(refreshView(true))
        }
      }
      // eslint-disable-next-line
    }, [actionStatus])
  return (
    <Container className={classes.container}>
      {/* Barra de búsqueda */}
      <Paper elevation={3}>
        <Container className={classes.searchContainer}>
          <Box className={classes.searchBox}>
            <TextField
              label="Buscar"
              className={classes.searchInput}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value as string)}
            />
            <FormControl className={classes.selectInput}>
              <InputLabel id="reference-select-label">
                {t("inventoryCrossCheck.seller")}
              </InputLabel>
              <Select
                labelId="reference-select-label"
                value={sellerId}
                onChange={(e) => setSellerId(e.target.value as string)}
              >
                {userList?.users.map((user: any) => (
                  <MenuItem key={user.id} value={user.id as string}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.buttonsBox}>
            <Button
              variant="contained"
              color="primary"
              disabled={!sellerId && !searchTerm}
              onClick={handleClearFilters}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!sellerId && !searchTerm}
              onClick={onSearch}
            >
              Buscar
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={3}>
        <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
          {loading && loading !== null ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : itemsForReview?.data.content.length === 0 ? (
            <Typography
              variant="h6"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              {t("inventoryCrossCheck.noItemsToShow")}
            </Typography>
          ) : (
            <>
              {itemsForReview?.data?.content.map((item: RequestsItem) => (
                <Container key={item.id} className={classes.itemContainer}>
                  <Box className={classes.itemInfo}>
                    <Typography variant="caption">
                      {t("inventoryCrossCheck.id")}
                      {item.id}
                    </Typography>
                    <Typography variant="subtitle1">{item.itemName}</Typography>
                    <Typography variant="subtitle1">
                      Enviado: {item.sellerQuantity}
                    </Typography>
                    <Typography variant="subtitle1">
                      Sistema: {item.systemQuantity}
                    </Typography>
                    <Typography variant="subtitle1">
                      Diferencia: {item.differenceQuantity}
                    </Typography>
                  </Box>
                  <Box className={classes.buttonsBoxItems}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleChangeStatus(item.id, false)}
                    >
                      Rechazar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleChangeStatus(item.id, true)}
                    >
                      Aprobar
                    </Button>
                  </Box>
                </Container>
              ))}
            </>
          )}

          {totalPages > 1 && (
            <Pagination
              current={currentPage}
              path="/admin/cross-check-management"
              pagesNumber={totalPages}
            />
          )}
        </div>
      </Paper>
    </Container>
  )
}

export default InventoryCrossCheckManagements
