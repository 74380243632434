import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl:{
      width: '100%',
      marginBottom: '1rem',
    }
  }),
);

export default useStyles;
