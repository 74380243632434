import {
  INVENTORY_CROSS_FAIL,
  INVENTORY_CROSS_LIST,
  INVENTORY_CROSS_LOADING,
  INVENTORY_CROSS_REVOKE,
  INVENTORY_CROSS_SUCCESS,
  INVENTORY_ITEMS_FOR_REVIEW,
  INVENTORY_STORES_FOR_ITEM_LIST,
  InventoryCrossData,
  InventoryCrossDispatchTypes,
  InventoryCrossRevokedData,
  REVOKE_REFRESH,
} from "../actions/inventoryCrossActions/InventoryCrossActionsTypes"

interface InventoryState {
  data?: InventoryCrossData
  loading: string
  actionStatus?: any
  revokedItems?: InventoryCrossRevokedData
  refresh?: boolean
  storesList?: any
  itemsForReview?: any
}

const initialState: InventoryState = {
  loading: "",
}

const InventoryCrossReducer = (
  state = initialState,
  action: InventoryCrossDispatchTypes
): InventoryState => {
  switch (action.type) {
    case INVENTORY_CROSS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case INVENTORY_CROSS_LIST:
      return { ...state, loading: "", data: action.payload }

    case INVENTORY_CROSS_FAIL:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "error" },
      }

    case INVENTORY_CROSS_SUCCESS:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case INVENTORY_CROSS_REVOKE:
      return {
        ...state,
        loading: "",
        revokedItems: action.payload,
      }
    case REVOKE_REFRESH:
      return {
        ...state,
        refresh: action.payload,
      }
    case INVENTORY_STORES_FOR_ITEM_LIST:
      return {
        ...state,
        loading: "",
        storesList: action.payload,
      }
    case INVENTORY_ITEMS_FOR_REVIEW:
      return {
        ...state,
        loading: "",
        itemsForReview: action.payload,
      }
    default:
      return state
  }
}

export default InventoryCrossReducer
