// Redux.
import { combineReducers } from "redux";

// Specific reducers.
import AuthReducer from './AuthReducer';
import BundleReducer from './BundleReducer';
import CartReducer from './CartReducer';
import CategoryReducer from './CategoryReducer';
import DeliveryReducer from "./DeliveryReducer";
import EventsReducer from "./EventsReducer";
import ItemReducer from './ItemReducer';
import ItemTypeReducer from './ItemTypeReducer';
import LogReducer from './LogReducer';
import NavReducer from './NavReducer';
import NotificationReducer from './NotificationReducer';
import OrderReducer from './OrderReducer';
import PaymentMethodsReducer from './PaymentMethodsReducers';
import PrizeReducer from "./PrizeReducer";
import SettingsReducer from './SettingsReducer';
import TemplateReducer from "./TemplateReducer";
import UserAddressReducer from './UserAddressReducer';
import UserReducer from './UserReducer';
import UtilityReducer from './UtilityReducer';
import VendorReducer from './VendorReducer';
import WarehouseReducer from './WarehouseReducer';
import WishlistReducer from './WishlistReducer';
import InventoryRequestReducer from "./InventoryRequestsReducer";
import InventoryCrossReducer from "./InventoryCrossReducer";

// Create the roo reducer to export.
const RootReducer = combineReducers({
  user: UserReducer,
  address: UserAddressReducer,
  item: ItemReducer,
  itemType: ItemTypeReducer,
  itemCategories: CategoryReducer,
  warehouse: WarehouseReducer,
  order: OrderReducer,
  auth: AuthReducer,
  settings: SettingsReducer,
  vendors: VendorReducer,
  cart: CartReducer,
  wishlist: WishlistReducer,
  paymentMethods: PaymentMethodsReducer,
  bundle: BundleReducer,
  log: LogReducer,
  utility: UtilityReducer,
  nav: NavReducer,
  userNotification: NotificationReducer,
  events: EventsReducer,
  prize: PrizeReducer,
  template: TemplateReducer,
  delivery: DeliveryReducer,
  inventoryRequests: InventoryRequestReducer,
  inventoryCross: InventoryCrossReducer,
});

export default RootReducer;