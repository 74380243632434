import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { refreshView, revokeItemReviewEntry } from "../../../actions/inventoryCrossActions/InventoryCrossActions"
import { RootStore } from "../../../Store"
import ModalChangeQuantity from "../inventoryCross/ModalChangeQuantity"
import Pagination from "../utils/ResultsPagination"
import useStyles from "./InventoryCrossCheckRequestsStyles"

const InventoryCrossCheckRequests = () => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()
  const { revokedItems, loading, refresh } = useSelector(
    (state: RootStore) => state.inventoryCross
  )
  const itemsList = revokedItems?.data.content
  const [currentPage, setCurrentPage] = useState<number>(
    revokedItems?.data.pageable.pageNumber ?? 0
  )
  const totalPages = revokedItems?.data.totalPages ?? 0

  const params = new URLSearchParams(window.location.search)
  let page = params.get("page")
  const location = useLocation()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [digitalQuantity, setDigitalQuantity] = useState<number>(0)
  const [physicalQuantity, setPhysicalQuantity] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [selectedItemId, setSelectedItemId] = useState<number>(0)

  const handleOpenModal = (
    quantity: number | null,
    digitalQuantity: number,
    itemId: number
  ) => {
    setDigitalQuantity(digitalQuantity)
    setPhysicalQuantity(quantity ?? 0)
    setSelectedItemId(itemId)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value)
  }

  const handleSearch = () => {
    dispatch(
      revokeItemReviewEntry({
        name: searchTerm,
        paged: true,
        size: 10,
        page: 0,
      })
    )
    window.history.replaceState(null, "", location.pathname)
  }

  useEffect(() => {
    dispatch(revokeItemReviewEntry({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(
      revokeItemReviewEntry({
        name: searchTerm,
        paged: true,
        size: 10,
        page: page ? parseInt(page) - 1 : 0,
      })
    )
    dispatch(refreshView(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, refresh])



  
  return (
    <div>
      {/* Buscador */}
      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              fullWidth
              placeholder={t("inventoryCrossCheck.search")}
              onChange={handleSearchTermChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSearch}
            >
              {t("inventoryCrossCheck.search")}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Listado */}
      <Paper elevation={3} className={classes.paper}>
        <Grid container>
          {
            // Si no hay datos, mostrar mensaje
            loading && loading !== null ? (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            ) : !itemsList || itemsList.length === 0 ? (
              <Typography variant="h6" className={classes.notItems}>
                {t("inventoryCrossCheck.noItemsToShow")}
              </Typography>
            ) : (
              <>
                {itemsList?.map((item, index) => (
                  <Grid item xs={12} key={item.id} className={classes.gridItem}>
                    <div className={classes.textSection}>
                      <Typography variant="caption" color="textSecondary">
                        {t("items.id")} {item.id}
                      </Typography>
                      <Typography variant="subtitle1">
                        {item.itemName}
                      </Typography>
                      <div className={classes.quantitySection}>
                        <Typography variant="subtitle1">
                          {t("inventoryCrossCheck.digitalQuantity")}{" "}
                          {item.digitalQuantity ?? 0}
                        </Typography>
                        <Typography variant="subtitle1">|</Typography>
                        <Typography variant="subtitle1">
                          {t("inventoryCrossCheck.physicalQuantity")}{" "}
                          {item.quantity ?? 0}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.buttonSection}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpenModal(
                            item.quantity,
                            item.digitalQuantity ?? 0,
                            item.id
                          )
                        }
                      >
                        {t("inventoryCrossCheck.review")}
                      </Button>
                    </div>
                  </Grid>
                ))}
              </>
            )
          }
        </Grid>
        {totalPages > 1 && (
          <Pagination
            current={currentPage}
            path="/admin/cross-check-requests"
            pagesNumber={totalPages}
          />
        )}
      </Paper>

      <ModalChangeQuantity
        digitalQuantity={digitalQuantity}
        physicalQuantity={physicalQuantity}
        itemId={selectedItemId}
        open={openModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  )
}

export default InventoryCrossCheckRequests
