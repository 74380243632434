import { Button, CircularProgress, Paper, Typography } from "@material-ui/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootStore } from "../../../Store"
import ImageAvatar from "../utils/ImageAvatar"
import Pagination from "../utils/ResultsPagination"
import useStyles from "./ItemListStyles"
import ModalRegisterQuantity from "./ModalRegisterQuantity"

interface ItemListProps {
  vendorId: number
}

const ItemList = ({ vendorId }: ItemListProps) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const { data, loading } = useSelector(
    (state: RootStore) => state.inventoryCross
  )

  const [currentPage, setCurrentPage] = useState<number>(
    data?.data.pageable.pageNumber ?? 0
  )
  const totalPages = data?.data.totalPages ?? 0
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [digitalQuantity, setDigitalQuantity] = useState<number>(0)
  const [selectedItemId, setSelectedItemId] = useState<number>(0)
  const handleOpenModal = (quantity: number, itemId: number) => {
    setDigitalQuantity(quantity)
    setSelectedItemId(itemId)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Paper elevation={3} style={{ marginBottom: "20px" }}>
        {loading && loading !== null ? (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        ) : !data || !data?.data || data.data.content.length === 0 ? (
          <Typography variant="h6" className={classes.notItems}>
            No hay items para mostrar
          </Typography>
        ) : (
          <div className={classes.container}>
            {data?.data.content.map((item, index) => (
              <div className={classes.itemContainer} key={index}>
                <div className={classes.itemImage}>
                  <ImageAvatar src={item.imageURL} alt="Item main image." />
                </div>
                <div className={classes.itemDetails}>
                  <Typography
                    variant="subtitle1"
                    className={classes.truncatedText}
                  >
                    {t("items.name")} {item.itemName}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t("items.sku")} {item.itemId}
                  </Typography>
                </div>
                <Typography
                  variant="subtitle1"
                  style={{ flex: 1, display: "flex" }}
                >
                  {t("inventoryCrossCheck.digitalQuantity")} {item.currentStock}
                </Typography>
                <div className={classes.itemActions}>
                  <Button
                    color="primary"
                    onClick={() =>
                      handleOpenModal(item.currentStock, item.itemId)
                    }
                  >
                    {t("inventoryCrossCheck.registerQuantity")}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
        {totalPages > 1 && (
          <Pagination
            current={currentPage}
            path="/admin/inventory-cross-check"
            pagesNumber={totalPages}
          />
        )}
      </Paper>

      <ModalRegisterQuantity
        digitalQuantity={digitalQuantity}
        vendorId={vendorId}
        itemId={selectedItemId}
        open={openModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export default ItemList
