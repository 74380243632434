import { useState } from "react"
import SearchStore from "../../reusable/SearchStore"
import ItemList from "../inventoryCross/ItemList"
import ItemSearchFilters from "../inventoryCross/ItemSearchFilters"
const InventoryCrossCheckPage = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [selectedVendor, setSelectedVendor] = useState<string>("")
  return (
    <>
      <SearchStore
        setShowFilters={setShowFilters}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
      />
      {showFilters ? (
        <ItemSearchFilters selectedVendor={selectedVendor} />
      ) : null}

      <ItemList vendorId={parseInt(selectedVendor)} />
    </>
  )
}
export default InventoryCrossCheckPage
