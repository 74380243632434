import {
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../Store"
import {
  getAllVendorBySeller,
  getAllVendors,
} from "../../actions/vendorActions/VendorActions"
import useStyles from "./SearchStoreStyles"

interface SearchStoreProps {
  setShowFilters?: React.Dispatch<React.SetStateAction<boolean>>
  selectedVendor: string
  setSelectedVendor: Function
}

const SearchStore: React.FC<SearchStoreProps> = ({
  setShowFilters,
  selectedVendor,
  setSelectedVendor,
}) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()
  const reduxVendorsState = useSelector((state: RootStore) => state.vendors)
  const vendorsList = reduxVendorsState.vendorList


  const [isFirstSelection, setIsFirstSelection] = useState<boolean>(true)

  const handleToggleFilters = () => {
    if (setShowFilters) {
      setShowFilters((prevState) => !prevState);
    }
  };

  const handleChangeVendor = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedVendor(e.target.value as string)
    if (isFirstSelection) {
      handleToggleFilters()
      setIsFirstSelection(false)
    }
  }
  useEffect(() => {
    dispatch(getAllVendors(null))
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <Paper
        elevation={3}
        style={{ paddingInline: "20px", marginBottom: "10px" }}
      >
        <Fragment>
          <FormControl className={classes.formControl}>
            <InputLabel id="store-checkbox-label">
              {t("orders.select-store")}
            </InputLabel>
            <Select
              labelId="store-checkbox-label"
              id="store-checkbox"
              input={<Input />}
              value={selectedVendor}
              onChange={handleChangeVendor}
            >
              {vendorsList?.vendors.map((vendor) => (
                <MenuItem key={vendor.id} value={vendor.id}>
                  <ListItemText primary={vendor.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Fragment>
      </Paper>
    </div>
  )
}

export default SearchStore
