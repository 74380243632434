import {Dispatch} from "redux";
import axios from 'axios';
import {USER_FAIL, USER_PUT, USER_LOADING, USER_LIST, USER_REFRESH, USER_SET_FILTER, ADD_USER, 
  DELETE_USER, UPDATE_USER_PASSWORD_TEMP, UPDATE_USER_POINTS, UserDispatchTypes} from "./UserActionsTypes";
import jwtDecode from "jwt-decode";
const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the refresh view state.
 * @param {boolean} value
 */
export const refreshUserView = (value: boolean) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  dispatch({
    type: USER_REFRESH,
    payload: value
  })
};

/**
 * Update the filters view state.
 * @param {object} filters
 */
export const userSetFilters = (filters: object) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  dispatch({
    type: USER_SET_FILTER,
    payload: filters
  })
};

/**
 * Create a new user on the BE.
 * @param {any} user
 */
export const addUser = (user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: USER_LOADING,
        payload: 'ADD_USER'
      })

      const bodyForm = {
        "email": user.email,
        "firstName": user.name,
        "lastName": user.lastName,
        "phoneNumber": user.phone,
        "role": user.role,
        "sellerVendorId": user.sellerVendorId
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/admin/registerUser`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_USER,
          payload: 'status.success-create-user-admin'
        })
      }
       
    } catch(e) {
      dispatch({
        type: USER_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: USER_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Get a list of users from the BE, it can be filtered.
 * @param {object|null} qParams
 */
export const getUsers = (qParams: object|null) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: USER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const decodedToken:any = jwtDecode(String(token));
      const pathRole = decodedToken.auth.includes('ROLE_ADMIN') ? 'admin' : 'seller'; 

      const res = await axios.get(`${apiURL}/${pathRole}/getAllUsers`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const users:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const user = queryData[i];
        
          if (!user.isDeleted) {
            users.push({
              id: user.sellerProfile.id,
              email: user.email,
              name: user.firstName,
              lastName: user.lastName,
              image_key: user.profile_image_key,
              profileImageUrl: user.profileImageUrl,
              phone: user.phoneNumber,
              address: user.addresses[0],
              role: user.roles,
              points: user.points,
            });
          }
        }

        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          users: users
        };

        dispatch({
          type: USER_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: USER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: USER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: USER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of users from the BE, it can be filtered.
 * @param {object|null} qParams
 */
export const getAllUsersForPoints = (qParams: object | null) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: USER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${apiURL}/admin/getAllUsersForPoints`, {
        params: qParams,
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data.content;
        const users: any = [];

        for (var i = 0; i < queryData.length; i++) {
          const user = queryData[i];
          if (!user.isDeleted) {
            users.push({
              email: user.email,
              name: user.firstName,
              lastName: user.lastName,
              profileImageUrl: user.profileImageUrl,
              points: user.points,
              phone: user.phoneNumber
            });
          }
        }

        const data: any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          users: users
        };

        dispatch({
          type: USER_LIST,
          payload: data
        })
      } else {
        dispatch({
          type: USER_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: USER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: USER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Update the given user on the BE.
 * @param {any} user
 */
export const updateUser = (user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: 'USER_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('email', user.email);
    user.name && bodyFormData.append('firstName', user.name);
    user.lastName && bodyFormData.append('lastName', user.lastName);
    user.password && bodyFormData.append('password', user.password);
    user.phone && bodyFormData.append('phoneNumber', user.phone);
    user.profileImageUrl && bodyFormData.append('profileImageUrl', user.profileImageUrl);
    user.imgFile && bodyFormData.append("file", user.imgFile, user.imgFile.name);
    user.role && bodyFormData.append('role', user.role);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ apiURL }/admin/updateUserInformation`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: USER_PUT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: USER_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: USER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Updates the user informacion.
 * @param {any} user
 */
 export const updateNormalUser = (user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  const sessionToken = localStorage.token;
  try {
    dispatch({
      type: USER_LOADING,
      payload: 'USER_UPDATE'
    })

    var bodyFormData = new FormData();
    user.name && bodyFormData.append('firstName', user.name);
    user.lastName && bodyFormData.append('lastName', user.lastName);
    user.oldPassword && bodyFormData.append('oldPassword', user.oldPassword);
    user.password && bodyFormData.append('newPassword', user.password);
    user.phone && bodyFormData.append('phoneNumber', user.phone);
    user.profileImageUrl && bodyFormData.append('profileImageUrl', user.profileImageUrl);
    user.imgFile && bodyFormData.append("file", user.imgFile, user.imgFile.name);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + sessionToken,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ apiURL }/user`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: USER_PUT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: USER_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: USER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Updates the user phone informacion.
 * @param {string} phoneNumner
 */
export const updateUserPhone = (phoneNumner: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  const sessionToken = localStorage.token;
  try {
    dispatch({
      type: USER_LOADING,
      payload: 'USER_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('phoneNumber', phoneNumner);

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + sessionToken,
        'Content-Type': 'multipart/form-data',
      }
    };

    const res = await axios.put(`${apiURL}/user`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: USER_PUT,
        payload: 'status.success-update-phone'
      })
    } else {
      dispatch({
        type: USER_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: USER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update user password from temportal.
 * @param {string} password
 */
 export const updatePasswordFromTemporal = (password: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  const sessionToken = localStorage.token;
  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: USER_LOADING,
        payload: 'UPDATE_USER'
      })

      const bodyForm = {
        "password": password,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + sessionToken,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.put(`${apiURL}/user/updatePasswordFromTemporal`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_USER_PASSWORD_TEMP,
          payload: 'status.success-update'
        })
      }
       
    } catch(e) {
      dispatch({
        type: USER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: USER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Soft delete a given user.
 * @param {string} email
 */
 export const deleteUser = (email: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: USER_LOADING,
        payload: 'DELETE_USER'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const deleteRes = await axios.delete(`${apiURL}/admin/deleteUser/${email}`, configPUT);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_USER,
          payload: 'status.success-delete-user'
        })
      }
       
    } catch(e) {
      dispatch({
        type: USER_FAIL,
        payload: 'status.delete-error'
      })
    }
  } else {
    dispatch({
      type: USER_FAIL,
      payload: 'status.delete-error'
    })
  }
};

/**
 * Updates the user informacion.
 * @param {number} points
 * @param {string} reason
 * @param {string} email
 */
export const updateUserPoints = (points: number, reason: string, email: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  const sessionToken = localStorage.token;
  try {
    dispatch({
      type: USER_LOADING,
      payload: 'USER_UPDATE'
    })

    var bodyFormData = {
      points: points,
      reasonOfPoints: reason,
      userEmail: email
    };

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + sessionToken,
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.put(`${apiURL}/admin/managePoints`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER_POINTS,
        payload: 'status.success-update-points'
      })
    } else {
      dispatch({
        type: USER_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: USER_FAIL,
      payload: 'status.error-update'
    })
  }
};
