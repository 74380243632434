import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      paddingLeft: "80px",
      margin: "20px",
      gap: "60px",
      borderBottom: "2px solid #0000004d",
      [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          gap: "20px",
          paddingLeft: "0px",
      },
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px', // Ajusta esto según sea necesario
    },
    notItems: {
      textAlign: 'center',
    },
    container: {
      paddingBottom: '20px',
    },
    truncatedText: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '3em', // Ajusta esto según sea necesario (2 líneas de texto)
      lineHeight: '1.5em', // Ajusta esto según sea necesario
      maxWidth: '200px', // Ajusta esto según sea necesario
      whiteSpace: 'normal', // Usa 'normal' para permitir el salto de líneas
       wordWrap: 'break-word',
      [theme.breakpoints.down("sm")]: {
        textAlign: 'center',
      },
    },
    itemDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      marginLeft: theme.spacing(12),
      [theme.breakpoints.down("sm")]: {
        textAlign: 'center',
        marginLeft: theme.spacing(0),
    },
    },
    itemImage: {
      flexShrink: 0,
    },
    itemActions: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export default useStyles
