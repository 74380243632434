// React.
import React, { useState, useEffect } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../actions/userActions/UserActions";
import { registerEventPlayers } from "../../../actions/eventActions/eventActions";
import { RootStore } from "../../../Store";

// Model Types.
import { UserType } from '../models/ModelTypes';

// Componets.
import TextMaskPhone from "../utils/TextMaskPhone";

// Styles.
import useStyles from './AddPlayersStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import TempPlayerList from "./TempPlayerList";

import { v4 as uuidv4 } from 'uuid';

interface AutoCompleteType {
  email: string;
  points: number;
  firstName: string;
  lastName: string;
  phone: string | undefined;
}

interface AddPlayersProps {
  id: string;
};
const AddPlayers = ({ id } : AddPlayersProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const userState = useSelector((state: RootStore) => state.user);
  const { loading, userList, actionStatus } = userState;
  const existingUser = userState.userList;
  
  const reduxEventState:any = useSelector((state: RootStore) => state.events);
  const { eventDetail, eventPlayers } = reduxEventState;

  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const { eventPrizeDetails } = reduxPrizeState;

  // Local state.
  // Default stage object.
  const defaulPlayer = {
    tempKey: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  };
  const [open, setOpen] = useState<boolean>(false);
  const [emailIsInUse, setEmailIsInUse] = useState<boolean>(false);
  const [existingPlayer, setExistingPlayer] = useState<boolean>(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<AutoCompleteType | null>(null);
  const [options, setOptions] = useState<AutoCompleteType[]>([]);

  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [playerState, setPlayerState] = useState<any>(defaulPlayer);

  const [playersTemp, setPlayersTemp] = useState<Array<any>>([]);

  useEffect(() => {
    const tempPlayers = localStorage.getItem('temp-players');
    if (tempPlayers) {
      setPlayersTemp(JSON.parse(tempPlayers));
    }
  }, []);

  const getUserData = () => {
    const delayDebounceFn = setTimeout(() => {
      if (!loading) {
        const qParams = { searchTerm: autocompleteValue, page: 0, size: 15, paged: true };
        dispatch(getUsers(qParams));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  };

  // Get the users list.
  useEffect(() => {
    if(autocompleteValue.length>2){
      getUserData();
    }
    // eslint-disable-next-line
  }, [autocompleteValue]);

  // Refresh the users list when an update is done. 
  useEffect(() => {
    if (actionStatus?.menssage === 'status.success-update-points') {
      getUserData();
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {
    if (userList !== undefined) {
      var newUsersList: Array<AutoCompleteType> = [];
      userList?.users.forEach((user: UserType) => {
        let currentUser = {
          email: user.email,
          points: (user.points) ? user.points : 0,
          firstName: user.name,
          lastName: user.lastName,
          phone: user.phone
        };
        newUsersList.push(currentUser);
        if (selectedItem) {
          if (user.email === selectedItem.email) setSelectedItem(currentUser);
        }
      });
      setOptions(newUsersList);
    }
    // eslint-disable-next-line
  }, [userList]);

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAutocompleteValue(String(event.target.value));
    setSelectedItem(null);
  };

  // Handlers functions.
  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof playerState;
    var inputValue = String(event.target.value);

    if (name === 'firstName' || name === 'lastName') {
      const regExCleanNames = /[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,",',?,{,}]/g;
      inputValue = inputValue.replace(regExCleanNames, '');
    }

    setPlayerState({
      ...playerState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (inputValue === '') {
      if (name === 'firstName') {
        errors.push(nameString);
        setErrorArray(errors);
      }
    } else {
      var newErrors = errors.filter(function(value) { 
        return value !== nameString;
      });
      if (nameString === 'email') {
        newErrors = newErrors.filter(function(value){ 
          return value !== 'taken-email';
        });
      }
      setErrorArray(newErrors);
    }
  };

  const handleKeyUpEmail = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const valueInput = (event.target as HTMLInputElement).value;
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (valueInput.length === 0) {
        setEmailIsInUse(false);
        setErrorArray([]);
      }
    }
  }

  const getErrorMessageEmail = () => {
    var message = '';
    if ( handleChangeValidation('taken-email')) {
      message = t('users.email-taken');
    }
    return message;
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (playerState.firstName === '') {
      errors.push('firstName');
      result = false;
    }
    if (playerState.email === '') {
    } else {
      if (playerState.email !== undefined) {
        // eslint-disable-next-line
        let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
        if (!emailMask.test(playerState.email)) { 
          errors.push('email');
          result = false;  
        }
      }
      if (existingUser !== undefined && existingUser!.users.length > 0) {
        errors.push('taken-email');
        result = false;  
      }
      if (emailIsInUse) {
        errors.push('taken-email');
        result = false;  
      }
    }
    if (playerState.phoneNumber === '') {
    } else {
      if (playerState.phone !== undefined) {
        let phoneMask  = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
        if (!phoneMask.test(playerState.phoneNumber)) {
          errors.push('phone');
          result = false;
        }
      }
    }
    
    setErrorArray(errors);

    return result;
  };

  const validateEmailOnDB = () => {
    setEmailIsInUse(false);
    // See if the email is already used.
    if (playerState.email !== '') {
      const qParams = {
        email: playerState.email
      };
      dispatch(getUsers(qParams));

      //See if the email is already used in the event.
      setEmailIsInUse(false);
      if (eventPlayers !== undefined && eventPlayers.length > 0) {
        if (eventPlayers.find((player: any) => player.email === playerState.email)) {
          setEmailIsInUse(true);
        }  
      } else {
        const tempPlayers = localStorage.getItem('temp-players');
        if (tempPlayers) {
          const currentList = JSON.parse(tempPlayers);
          if (currentList.length > 0) {
            if (currentList.find((player: any) => player.email === playerState.email || player.userEmail === playerState.email)) {
              setEmailIsInUse(true);
            }
          }
        }
      }
    }
  };

  const onAddRegisteredUser = () => {
    //See if the email is already used in the event.
    if (eventPlayers !== undefined && eventPlayers) {
      if (eventPlayers.find((player: any) => player.email === selectedItem?.email)) {
        setExistingPlayer(true);
        return;
      }
    }
    const player = [{
      tempKey: uuidv4(),
      firstName: selectedItem?.firstName,
      lastName: selectedItem?.lastName,
      phoneNumber: selectedItem?.phone,
      userEmail: selectedItem?.email
    }];
    // Save player in the current event.
    dispatch(registerEventPlayers(player, parseInt(id, 10)));
    setExistingPlayer(false);
  };

  const onAddAnonymousUser = () => {
    // Save player in the current event
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      const player = [{
        tempKey: uuidv4(),
        firstName: playerState.firstName,
        lastName: playerState.lastName,
        phoneNumber: playerState.phoneNumber,
        email: playerState.email
      }];
      // Send to BE
      dispatch(registerEventPlayers(player, parseInt(id, 10)));
      setPlayerState(defaulPlayer);
    }
  };

  const onAddRegisteredUserTemp = () => {
    //See if the email is already used in the event.
    const tempPlayers = localStorage.getItem('temp-players');
    if (tempPlayers) {
      const currentList = JSON.parse(tempPlayers);
      if (currentList.length > 0) {
        if (currentList.find((player: any) => player.userEmail === selectedItem?.email)) {
          setExistingPlayer(true);
          return;
        }
      }
    }
    const player = {
      tempKey: uuidv4(),
      firstName: selectedItem?.firstName,
      lastName: selectedItem?.lastName,
      phoneNumber: selectedItem?.phone,
      userEmail: selectedItem?.email
    }
    // Save player in the current temp list of players for this event.
    saveTempPlayersLocally(player);
    setExistingPlayer(false);
  };

  const onAddAnonymousUserTemp = () => {
    // Save player in the current event
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      const player = {
        tempKey: uuidv4(),
        firstName: playerState.firstName,
        lastName: playerState.lastName,
        phoneNumber: playerState.phoneNumber,
        email: playerState.email
      }
      saveTempPlayersLocally(player);
      setPlayerState(defaulPlayer);
    }
  };

  const saveTempPlayersLocally = (player: any) => {
    let tempPlayersData = [];
    const tempPlayers = localStorage.getItem('temp-players');

    if (tempPlayers) {
      tempPlayersData = JSON.parse(tempPlayers);
    }

    tempPlayersData.push(player);
    //Saving players on localstorage.
    localStorage.setItem('temp-players', JSON.stringify(tempPlayersData));
    //Refreshing data.
    setPlayersTemp(tempPlayersData);
  }

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  if ((eventDetail !== undefined && eventDetail.status === 'Finalizado') || eventPrizeDetails?.totalAwardsData !== null) {
    return (<></>)
  }

  return (
    <>
    <div className={ classes.root }>
      <Paper elevation={3} square={false} variant="elevation">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
              { t('events.add-players-title') }
              {loading !== '' && <CircularProgress size={28} />}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="asynchronous-items"
                    className={classes.autocomplete}
                    options={options}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedItem}
                    onChange={(event: any, newValue: AutoCompleteType | null) => {
                      setSelectedItem(newValue);
                      setExistingPlayer(false);
                    }}
                    getOptionSelected={(option, value) => option.email === value.email}
                    getOptionLabel={(option) => 
                      `${option.firstName} ${option.lastName} | ${option.email} | ${option.phone}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('users.user-search')}
                        value={autocompleteValue}
                        onChange={autocompleteChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider className={classes.marginBottom} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography color={existingPlayer? "primary" : "secondary" } className={classes.marginBottom}>
                    {existingPlayer? t('players.exist-player-error') : t('users.selected-user') }
                  </Typography>
                  <div className={classes.card}>
                    <div>
                      {(!selectedItem) &&
                        <form className={ classes.boxForm }>
                          <Typography>{t('players.no-selected-user')}</Typography>
                          <FormControl className={classes.formControl}>
                            <Typography variant="caption"><span className={classes.bold}>Email:</span></Typography>
                            <TextField 
                              className={classes.formField} 
                              id="u-email" 
                              name="email" 
                              type="email"
                              autoFocus
                              value={ playerState.email }
                              onKeyUp={ handleKeyUpEmail }
                              onChange={ handleChange }
                              onBlur={ validateEmailOnDB }
                              error={ handleChangeValidation('email') || handleChangeValidation('taken-email') }
                              helperText={getErrorMessageEmail()}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="caption"><span className={classes.bold}>{t('users.name')}:</span></Typography>
                            <TextField 
                              className={classes.formField} 
                              id="u-firstName" name="firstName" 
                              value={ playerState.firstName } 
                              onChange={ handleChange }
                              autoFocus
                              error={ handleChangeValidation('firstName') }
                              helperText={handleChangeValidation('firstName') ? t('events.field-required'): ''}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="caption"><span className={classes.bold}>{t('users.last-name')}:</span></Typography>
                            <TextField 
                              className={classes.formField} 
                              id="u-lastName" name="lastName" 
                              value={ playerState.lastName } 
                              onChange={ handleChange }
                              autoFocus
                              error={ handleChangeValidation('lastName') }
                              helperText={handleChangeValidation('lastName') ? t('events.field-required'): ''}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography variant="caption"><span className={classes.bold}>{t('users.phone-number')}:</span></Typography>
                            <Input 
                              className={classes.formField}
                              name="phoneNumber" 
                              value={ playerState.phoneNumber } 
                              onChange={ handleChange }
                              autoFocus
                              id="formatted-text-mask-input" 
                              inputComponent={TextMaskPhone as any}
                              error={ handleChangeValidation('phoneNumber') }
                            />
                          </FormControl>
                        </form>
                      }
                      {(selectedItem) &&
                        <>
                          <Typography><span className={classes.bold}>Email:</span> {selectedItem?.email}</Typography>
                          <Typography><span className={classes.bold}>{t('users.name')}:</span> {selectedItem?.firstName} {selectedItem?.lastName}</Typography>
                          <Typography><span className={classes.bold}>{t('users.phone-number')}:</span> {selectedItem?.phone}</Typography>
                        </>
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>  
          <Grid item xs={12} sm={5} className={ classes.buttonsWrapper}>
            <div className={ classes.buttons }>
              <Button 
                onClick={ (eventPlayers !== undefined && eventPlayers.length > 0) ? onAddRegisteredUser : onAddRegisteredUserTemp } 
                variant="contained" 
                disabled={!selectedItem} 
                color="primary">
                { t('events.add-user-btn') }
              </Button>
              <Button 
                onClick={ (eventPlayers !== undefined && eventPlayers.length > 0) ? onAddAnonymousUser : onAddAnonymousUserTemp } 
                variant="contained" 
                color="primary" 
                disabled={!!selectedItem || errorArray.length !== 0} 
                className={ classes.buttonsLast }>
                  { t('events.add-user-anonymous-btn') }
              </Button>
            </div>
          </Grid>  
        </Grid>        
      </Paper>
    </div>
    <TempPlayerList playersTemp={playersTemp} />
    </>
  )
}

export default AddPlayers;
