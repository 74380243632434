import {
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  refreshView,
  updateItemReviewEntry,
} from "../../../actions/inventoryCrossActions/InventoryCrossActions"
import { RootStore } from "../../../Store"
import useStyles from "./ModalRegisterQuantityStyles"

interface ModalChangeQuantityProps {
  digitalQuantity: number
  physicalQuantity: number
  open: boolean
  handleCloseModal: () => void
  itemId: number
}

const ModalChangeQuantity: React.FC<ModalChangeQuantityProps> = ({
  digitalQuantity,
  physicalQuantity,
  open,
  handleCloseModal,
  itemId,
}) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()
  // Manejar los cambios de cantidad física directamente en un estado local
  const [physicalQuantityModal, setPhysicalQuantityModal] = useState<
    number | string
  >(physicalQuantity)

  const { actionStatus } = useSelector(
    (state: RootStore) => state.inventoryCross
  )
  // Sincronizar el estado `physicalQuantityModal` con el valor de las props cuando el modal se abre
  useEffect(() => {
    if (open) {
      setPhysicalQuantityModal(physicalQuantity)
    }
  }, [physicalQuantity, open])

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhysicalQuantityModal(event.target.value)
  }

  const handleSendQuantities = () => {
    dispatch(
      updateItemReviewEntry({
        itemReviewEntryId: itemId,
        quantity: physicalQuantityModal,
      })
    )
    handleCloseModal()
  }

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === "status.success-update") {
        dispatch(refreshView(true))
      }
    }
    // eslint-disable-next-line
  }, [actionStatus])
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="Review Quantity Modal"
      className="modal-form"
    >
      <Paper elevation={3} className={classes.modalPaper}>
        <IconButton
          className={classes.closeModal}
          aria-label="close modal"
          component="span"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" color="primary">
          {t("inventoryCrossCheck.proposedChange")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("inventoryCrossCheck.originalQuantity")}
          {digitalQuantity}
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing={2} // Agrega espacio entre los elementos
        >
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary">
              {t("inventoryCrossCheck.proposedQuantity")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {" "}
            {/* Controla el ancho del TextField */}
            <TextField
              type="number"
              className={classes.inputProposedQuantity}
              value={physicalQuantityModal}
              onChange={handleQuantityChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.buttonsModal}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
            fullWidth
          >
            {t("inventoryCrossCheck.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSendQuantities}
            fullWidth
          >
            {t("inventoryCrossCheck.resubmit")}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default ModalChangeQuantity
