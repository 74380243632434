import {
  Button,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "./ModalRegisterQuantityStyles"
import { useDispatch } from "react-redux"
import { createItemReviewEntry } from "../../../actions/inventoryCrossActions/InventoryCrossActions"
interface ModalRegisterQuantityProps {
  digitalQuantity: number
  open: boolean
  handleCloseModal: () => void
  vendorId: number
  itemId: number
}

const ModalRegisterQuantity: React.FC<ModalRegisterQuantityProps> = ({
  digitalQuantity,
  open,
  handleCloseModal,
  vendorId,
  itemId,
}) => {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const classes = useStyles()
  const [quantity, setQuantity] = useState<number | string>("")

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value)
  }

  const handleClose = () => {
    setQuantity("")
    handleCloseModal()
  }
  const handleRegisterQuantity = () => {
    dispatch(createItemReviewEntry(vendorId, itemId, quantity as number))
    handleClose()
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Register Quantity Modal"
      className="modal-form"
    >
      <Paper elevation={3} className={classes.modalPaper}>
        <IconButton
          className={classes.closeModal}
          aria-label="close modal"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" color="primary">
          {t("inventoryCrossCheck.registerQuantity")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("inventoryCrossCheck.digitalQuantity")} {digitalQuantity}
        </Typography>
        <TextField
          label={t("inventoryCrossCheck.quantity")}
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          fullWidth
          margin="normal"
        />
        <div className={classes.buttonsModal}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            fullWidth
          >
            {t("inventoryCrossCheck.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleRegisterQuantity}
            fullWidth
          >
            {t("inventoryCrossCheck.submit")}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default ModalRegisterQuantity
