import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      padding: theme.spacing(6, 4),
      justifyContent: "center",
      textAlign: "center",
      position: "absolute",
      width: "400px",
      height: "auto",
      top: "calc(30% - 100px)",
      left: "calc(50% - 200px)",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(6, 2),
        width: "90%",
        left: "5%",
      },
      "& .MuiTypography-root": {
        marginBottom: theme.spacing(2),
      },
      "& .MuiButtonBase-root": {
        marginLeft: theme.spacing(2),
      },
      "& div > form": {
        padding: theme.spacing(0),
      },
      "& .MuiTypography-h5": {
        textAlign: "center",
      },
    },
    modalPaperConfirm: {
      textAlign: "center",
    },
    closeModal: {
      position: "absolute",
      right: "0",
      top: "0",
    },
    buttonsModal:{
      display: "flex",
      gap: "10px",
      "& .MuiButtonBase-root": {
      marginLeft:0,
    }
    },
    inputProposedQuantity:{
      marginTop: "0px",
      marginBottom: "16px",
      "& input":{
        textAlign:"center",
        padding: "2px"
      }

    }
  })
)

export default useStyles
