import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiAppBar-root': {
        zIndex: 1300
      }
    },
    nested: {
      paddingLeft: theme.spacing(4),
      '& .MuiTypography-root': {
        fontSize: '15px'
      }
    },
    nestedTwo: {
      paddingLeft: theme.spacing(6),
      '& .MuiTypography-root': {
        fontSize: '14px'
      }
    },
    sectionMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    appTitle: {
      flexGrow: 1,
    },
    appIcons: {
      [theme.breakpoints.down("xs")]: {
				display: 'inline-flex',
			}
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    notificationPopover: {
      '& .MuiPopover-paper': {
        top: '64px !important',
        left: 'unset !important',
        right: '15px',
        '&:before': {
          content: 'p'
        }
      }
    },
    notifications: {
      '& .MuiList-root': {
        maxWidth: '450px',
      },
      '& .MuiListItem-root': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '& span': {
        backgroundColor: theme.palette.primary.main,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        position: 'absolute',
        right: '20px',
        top: 'calc(50% - 5px)'
      }
    },
    nTitle: {
      width: '100%',
      maxWidth: '400px',
      fontFamily: 'VisbyCF-Bold',
      paddingRight: '15px'
    },
    nMessage: {
      width: '100%',
      maxWidth: '400px',
      fontSize: '12px',
      paddingRight: '15px'
    }
  }),
);