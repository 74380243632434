import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      margin: "10px",
      width: "100%",
      gap: "60px",
      paddingBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "20px",
      },
    },
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      width: "80%",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingRight: "20px",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      width: "20%",
      gap: "20px",
      paddingRight: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    
  })
)

export default useStyles
