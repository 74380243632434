import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    gridItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    textSection: {
      flex: 2, // Dos tercios del espacio
    },
    buttonSection: {
      flex: 1, // Un tercio del espacio
      textAlign: "right",
    },
    quantitySection:{
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px', // Ajusta esto según sea necesario
      width: "100%",
    },
    notItems: {
      textAlign: 'center',
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
  }));

  export default useStyles;