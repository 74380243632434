import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"
import { Stack } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getItemsForRevision } from "../../../actions/inventoryCrossActions/InventoryCrossActions"
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions"
import { RootStore } from "../../../Store"
import useStyles from "./ItemSearchFiltersStyle"

interface ItemSearchFiltersProps {
  selectedVendor: string
}

const ItemSearchFilters: FC<ItemSearchFiltersProps> = ({ selectedVendor }) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()
  const itemsTypeState = useSelector((state: RootStore) => state.itemType)
  const itemsTypeList = itemsTypeState.itemTypeList?.categories
  const [availability, setAvailability] = useState<boolean>(true)
  const [category, setCategory] = useState<string>("")
  const [searchTerm, setSearchTerm] = useState<string>("")
  const params = new URLSearchParams(window.location.search)
  let page = params.get("page")
  const location = useLocation()
  const handleAvailabilityButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAvailability(event.target.checked)
  }
  const handleResetButton = () => {
    setCategory("")
    setAvailability(true)
    setSearchTerm("")
    window.history.replaceState(null, "", location.pathname);
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value)
  }
  const onSearch = () => {
    dispatch(
      getItemsForRevision({
        vendorId: selectedVendor,
        itemType: parseInt(category),
        availability,
        searchTerm,
        paged: true,
        size: 10,
        page: page ? parseInt(page) - 1 : 0,
      })
    )
  }

  useEffect(() => {
    dispatch(getItemTypes())
  }, [dispatch])

  useEffect(() => {
    if (!category) {
      return
    }
    dispatch(
      getItemsForRevision({
        vendorId: selectedVendor,
        itemType: parseInt(category)!,
        availability,
        searchTerm,
        paged: true,
        size: 10,
        page: page ? parseInt(page) - 1 : 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location])

  return (
    <Paper elevation={3}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <TextField
            id="item-name"
            label={t("inventoryCrossCheck.item-name")}
            value={searchTerm}
            onChange={handleSearchTermChange}
          ></TextField>

          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">
              <Typography color="primary">
                {t("inventoryCrossCheck.stockAvailability")}
              </Typography>
            </FormLabel>
            <FormGroup>
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Switch
                  checked={availability}
                  onChange={handleAvailabilityButtonChange}
                  name="stockButton"
                  color="primary"
                />
                <Typography>{t("players.yes")}</Typography>
              </Stack>
            </FormGroup>
          </FormControl>
        </div>
        <div className={classes.rightContainer}>
          <FormControl fullWidth>
            <InputLabel id="reference-select-label">
              {t("inventoryCrossCheck.category")}
            </InputLabel>
            <Select
              labelId="reference-select-label"
              value={category}
              label="Seleccione la categoria"
              onChange={(e) => setCategory(e.target.value as string)}
            >
              {itemsTypeList?.map((itemType) => (
                <MenuItem key={itemType.id} value={itemType.id}>
                  {itemType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={onSearch}
            disabled={category === ""}
          >
            {t("inventoryCrossCheck.search")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleResetButton}
          >
            {t("inventoryCrossCheck.reset")}
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default ItemSearchFilters
