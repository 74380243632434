import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getItems } from "../../../actions/itemActions/ItemActions"
import { ItemAddedType } from "../../../actions/orderActions/OrderActionsTypes"
import { getProjectConfig } from "../../../getProjectConfig"
import { RootStore } from "../../../Store"
import { useStyles } from "./CreateInventoryRequestStyles"

interface ViewItemModalProps {
  open: boolean
  onClose: () => void
  itemCode: string
  itemsAdded: ItemAddedType[]
  itemQuantities: { [key: number]: number }
  quantityErrors: { [key: number]: boolean }
  handleChangeItemQuantity: (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => void
  addItemToCart: () => void
  selectedVendor: number
  currentItem?: any
}

const ViewItemModal: React.FC<ViewItemModalProps> = ({
  open,
  onClose,
  itemCode,
  itemQuantities,
  quantityErrors,
  handleChangeItemQuantity,
  addItemToCart,
  itemsAdded,
  selectedVendor,
  currentItem,
}) => {
  const classes = useStyles()
  const { t } = useTranslation("global")
  const dispatch = useDispatch()

  const itemState = useSelector((state: RootStore) => state.item)
  const itemList = itemState.itemList
  const [loadingb, setLoadingb] = useState<boolean>(false)

  const hasErrors = Object.values(quantityErrors).some((error) => error)
  const allQuantitiesZero = Object.values(itemQuantities).every(
    (quantity) => quantity === 0 || quantity === undefined || isNaN(quantity)
  )
  const filteredItemList = itemList?.items.filter(
    (item) => item.vendor && item.vendor.id !== selectedVendor
    
  )

  const getItemToShow = async (code: string) => {
    const qParamsItems = {
      category: "Sellado",
      code: code, // Usa el código del ítem que se pasa
      isStockPositive: true,
      page: 0,
      internalOrderStock: true,
      size: getProjectConfig().PAGES_SIZE,
      paged: true,
      isPublished: true,
    }

    // Llamada a la API para obtener los ítems
    await dispatch(getItems(qParamsItems, "seller"))
  }

  useEffect(() => {
    if (open) {
      setLoadingb(true) // Inicia el estado de carga
      getItemToShow(itemCode).finally(() => {
        setLoadingb(false) // Finaliza el estado de carga
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  useEffect(() => {
    if (!open) {
      // Limpia la información del modal cuando se cierra
      setLoadingb(false)
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modalProductInformation}
    >
      <Grid container direction="column" className={classes.containerModal}>
        {loadingb ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Paper elevation={3} className={classes.productInformationPaper}>
            <>
              <Avatar
                variant="rounded"
                src={
                  itemList &&
                  itemList.items.length > 0 &&
                  itemList.items[0]?.images &&
                  itemList.items[0].images.length > 0
                    ? itemList.items[0].images[0]?.url
                    : ""
                }
                alt="The main item image."
                className={classes.imageProduct}
              />
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  maxWidth: "60%",
                  marginLeft: "30px",
                }}
              >
                {itemList?.items[0]?.name}
              </Typography>

              <Typography variant="body1" gutterBottom>
                {t("inventoryRequests.sku")} {itemList?.items[0]?.code}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("inventoryRequests.category")} {itemList?.items[0]?.category}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("inventoryRequests.price")} {itemList?.items[0]?.price}
              </Typography>
            </>
          </Paper>
        )}

        <TableContainer className={classes.tableContainer}>
          {loadingb ? (
            <div className={classes.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.store")}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.stock-available")}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.amount-to-request")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredItemList?.map((item, index) => {
                  const maxQuantity = item?.totalQuantity ?? 0
                  const currentQuantity =
                    itemQuantities[index] !== undefined
                      ? itemQuantities[index]
                      : itemsAdded?.find(
                          (addedItem) => addedItem.item.id === item.id
                        )?.quantity ?? 0
                  const totalQuantity =
                    item?.warehousesQuantities?.reduce(
                      (
                        sum: number,
                        warehouseItem: {
                          warehouse: { isDiscount: boolean }
                          quantity: number
                        }
                      ) => {
                        if (!warehouseItem.warehouse.isDiscount) {
                          return sum + warehouseItem.quantity
                        }
                        return sum
                      },
                      0
                    ) ?? 0
                  const displayQuantity = currentItem?.vendors?.length
                    ? currentItem.vendors[index]?.quantity ?? 0
                    : totalQuantity
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>
                        {item?.vendor?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {displayQuantity}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <FormControl
                          className={`${classes.fieldClass} ${classes.quantityInput}`}
                          size="small"
                        >
                          <TextField
                            id={`item-quantity-${index}`}
                            name="quantity"
                            type="number"
                            inputProps={{ min: 0, max: maxQuantity }}
                            value={currentQuantity}
                            onChange={(event) =>
                              handleChangeItemQuantity(event, index)
                            }
                            error={quantityErrors[index]}
                            disabled={
                              itemList?.items[0]?.category !== "Sellado"
                            }
                          />
                          {quantityErrors[index] && (
                            <Typography variant="caption" color="error">
                              {t("orders.quantity-greater-error")}
                            </Typography>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Grid item className={classes.buttonBottomModal}>
          <Button variant="contained" onClick={onClose}>
            {t("inventoryRequests.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addItemToCart}
            disabled={hasErrors || allQuantitiesZero}
          >
            {t("inventoryRequests.add-to-request")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ViewItemModal
