import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
    padding: "20px",
  },
  searchBox: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  searchInput: {
    width: "85%",
  },
  selectInput: {
    width: "15%",
  },
  buttonsBox: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
  },
  itemContainer:{
    display: "flex",
    width: "100%",
    gap: "20px",
    padding: "20px",
    borderBottom: "2px solid #0000004d",
  },
  itemInfo :{
    display: "flex",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "40px",
  },
  buttonsBoxItems : {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "20%",
    gap: "20px",
    justifyContent: "center",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
})

export default useStyles
