import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import { Autocomplete } from "@material-ui/lab"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../Store"
import { useStyles } from "./CreateInventoryRequestStyles"

//  JWT decode token.
import { Add } from "@mui/icons-material"
import jwtDecode from "jwt-decode"
import {
  getItems,
  getSuggestedOutOfStock,
} from "../../../actions/itemActions/ItemActions"
import {
  itemAddedListUpdate,
  registerInternalOrder,
} from "../../../actions/orderActions/OrderActions"
import { InternalOrderRequest } from "../../../actions/orderActions/OrderActionsTypes"
import {
  getAllVendorBySeller,
  getVendors,
} from "../../../actions/vendorActions/VendorActions"
import { getProjectConfig } from "../../../getProjectConfig"
import { ItemType } from "../models/ModelTypes"
import SummaryRequest from "./SummaryRequest"
import ViewItemModal from "./ViewItemModal"
import { set } from "react-ga"

const CreateInventoryRequest = ({ defaultQuantityOnSelect = false }) => {
  const classes = useStyles()
  const [t] = useTranslation("global")
  const dispatch = useDispatch()

  const [currentItem, setCurrentItem] = useState<ItemType | undefined>(
    undefined
  )
  const [typingValue, setTypingValue] = useState<string>("")
  const [clearSearch, setClearSearch] = useState<boolean>(false)
  const [itemQuantities, setItemQuantities] = useState<{
    [key: number]: number
  }>({})
  const [quantityErrors, setQuantityErrors] = useState<{
    [key: number]: boolean
  }>({})
  const [searchOptions, setSearchOptions] = useState<Array<any>>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [vendorError, setVendorError] = useState<boolean>(false)
  const [searchItem, setSearchItem] = useState<ItemType | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order)
  const itemsAdded = orderState.addedInOrder?.itemAddedList
  const itemState = useSelector((state: RootStore) => state.item)
  const itemList = itemState.itemList
  const suggestedItems = itemState.suggestedOutOfStock
  const vendorState = useSelector((state: RootStore) => state.vendors)
  const vendorsListBySeller = vendorState.vendorsBySeller
  const vendorsList = vendorState.vendors
  const [selectedVendor, setSelectedVendor] = useState<number>(0)

  const filteredItemList = itemList?.items.filter(
    (item) => item.vendor && item.vendor.id !== selectedVendor
  )
  const filteredVendorsList = vendorsList.filter(
    (vendor: any) =>
      !vendorsListBySeller?.some(
        (sellerVendor: any) => sellerVendor.vendorId === vendor.id
      )
  )
  const actionStatus = useSelector(
    (state: RootStore) => state.order.actionStatus
  )

  useEffect(() => {
    dispatch(getAllVendorBySeller())
    dispatch(getVendors())
    if (!decodedToken.auth.includes("ROLE_ADMIN")) {
      getSuggestedItemsToShow()
    }

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (vendorsListBySeller.length > 0) {
      setSelectedVendor(
        vendorsListBySeller.length > 1 ? 0 : vendorsListBySeller[0].vendorId
      )
    }
  }, [vendorsListBySeller])
  useEffect(() => {
    if (typingValue !== "") {
      const delayDebounceFn = setTimeout(
        () => triggerSearchItemsAutocomplete(),
        500
      )
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [typingValue])

  useEffect(() => {
    if (actionStatus?.status === "success") {
      cleanRequest()
      setClearSearch(true)
      setTypingValue("")
      setCurrentItem(undefined)
    }
    // eslint-disable-next-line
  }, [actionStatus])

  // Update the search options array.
  useEffect(() => {
    if (itemList !== undefined) {
      const optionsSet = new Set<string>();
      const optionsList: Array<any> = [];

      // Add items results.
      itemList?.items.forEach((option: any) => {
        if (!optionsSet.has(option.name) && option.vendor.id !== selectedVendor) {
          optionsSet.add(option.name);
          optionsList.push({ element: option, type: "item" });
        }
      });

      setSearchOptions(optionsList);
    }

    // eslint-disable-next-line
  }, [itemList]);

  const decodedToken: any =
    localStorage.token !== undefined && localStorage.token !== ""
      ? jwtDecode(localStorage.token)
      : false

  const isSuperSeller = decodedToken
    ? decodedToken.auth.includes("ROLE_SUPER_SELLER")
    : false
  const isAdmin = decodedToken
    ? decodedToken.auth.includes("ROLE_ADMIN")
    : false
  const isSuperAdminOrSuperSeller = () => {
    if (decodedToken) {
      if (isAdmin || isSuperSeller) {
        return true
      }
    }
  }
  const handleChangeVendor = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedVendorId = event.target.value as number
    const vendorExists = itemsAdded?.some(
      (item) => item.item.vendor.id === selectedVendorId
    )
    setSelectedVendor(selectedVendorId)
    if (vendorExists) {
      setVendorError(true)
    } else {
      setVendorError(false)
    }
  }
  const getSuggestedItemsToShow = () => {
    // Obtener los ítems sugeridos
    const vendorId = selectedVendor !== 0 ? selectedVendor : undefined
    dispatch(getSuggestedOutOfStock("seller", vendorId))
  }
  const triggerSearchItemsAutocomplete = () => {
    const qParamsItems = {
      category: "Sellado",
      name: typingValue,
      page: 0,
      isStockPositive: true,
      size: getProjectConfig().PAGES_SIZE,
      paged: true,
      internalOrderStock: true,
      isPublished: true,
    }

    dispatch(getItems(qParamsItems, "seller"))
  }



  const handleChangeItemName = (event: any, selectedItem: any) => {
    if (selectedItem.type === "item") {
      setCurrentItem(selectedItem.element)
      setSearchItem(selectedItem.element)
    }
    if (defaultQuantityOnSelect) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [selectedItem.element.id]: 1,
      }))
    }
  }
  const handleOpenViewItem = () => {
    if (searchItem) {
      setCurrentItem(searchItem)
      setOpenModal(true)
    }
  }
  const handleChangeTyping = (event: React.ChangeEvent<{ value: string }>) => {
    setTypingValue(event.target.value)
  }
  const handleDeleteItem = (itemId: number) => {
    const newItems = itemsAdded?.filter((i) => i.item.id !== itemId) || []
    dispatch(itemAddedListUpdate(newItems, []))
  }

  const handleEditItem = (item: any) => {
    setCurrentItem(item.item)
    setOpenModal(true)
  }

  const handleViewSuggestedItem = async (item: any) => {
    setCurrentItem(item)
    setOpenModal(true)
  };

  const handleChangeItemQuantity = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const value = event.target.value as string

    // Permitir valores vacíos temporalmente
    if (value === "") {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: parseInt(value),
      }))
      setQuantityErrors((prevErrors) => ({
        ...prevErrors,
        [index]: false,
      }))
      return
    }

    const quantity = Number(value)
    const item = itemList?.items[index]

    if (item) {
      let vendorStock = item.totalQuantity

      // Items in cart.
      let itemsInCart = 0
      if (orderState.addedInOrder?.itemAddedList) {
        orderState.addedInOrder.itemAddedList.forEach((i) => {
          if (i.item.id === item.id) {
            itemsInCart = i.quantity
          }
        })
      }

      const available = vendorStock - itemsInCart

      if (quantity > available || quantity < 1) {
        setQuantityErrors((prevErrors) => ({
          ...prevErrors,
          [index]: true,
        }))
      } else {
        setQuantityErrors((prevErrors) => ({
          ...prevErrors,
          [index]: false,
        }))
      }

      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: quantity,
      }))
    }
  }

  const addItemToCart = () => {
    const currentListItems = itemsAdded !== undefined ? itemsAdded : []

    const hasValidQuantity = Object.values(itemQuantities).some(
      (quantity) => quantity > 0
    )

    const hasErrors = Object.values(quantityErrors).some((error) => error)

    if (!hasValidQuantity || hasErrors) {
      return
    }

    Object.keys(itemQuantities).forEach((index) => {
      const itemIndex = Number(index)
      const item = filteredItemList?.[itemIndex]
      const quantity = itemQuantities[itemIndex]

      if (item && quantity !== undefined) {
        // Validate if the item is already added.
        const existingItemIndex = currentListItems.findIndex(
          (element) =>
            element.item.id === item.id &&
            element.whId === Number(item.vendor?.id)
        )

        if (existingItemIndex !== -1) {
          currentListItems[existingItemIndex].quantity = quantity
        } else {
          currentListItems.push({
            item: item,
            quantity: quantity,
            customReference: "",
            whId: Number(item.vendor?.id),
          })
        }
      }
    })

    dispatch(itemAddedListUpdate(currentListItems, []))
    setItemQuantities({})
    setQuantityErrors({})
    setOpenModal(false)
  }

  const cleanRequest = () => {
    setItemQuantities({})
    setQuantityErrors({})
    dispatch(itemAddedListUpdate([], []))
  }

  const registerRequest = async () => {
    const items = itemsAdded?.map((item) => ({
      itemId: item.item.id,
      quantity: item.quantity,
      vendorId: item.whId,
    }));
  
    const orderRequest: InternalOrderRequest = {
      items: items ?? [],
      vendorId: selectedVendor,
    };
  
    const vendorId = selectedVendor !== 0 ? selectedVendor : undefined
    await dispatch(registerInternalOrder(orderRequest));
  
    // Recarga la lista de sugeridos después de crear la orden
    await dispatch(getSuggestedOutOfStock("seller", vendorId))
  };


  
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          {/* Input to search an item */}
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.boxTop}>
                <ConfirmationNumberIcon color="primary" fontSize="large" />
                <Typography variant="h5" color="primary">
                  {t("inventoryRequests.search-item")}
                </Typography>
              </div>
              <div>
                <form className={classes.boxForm}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        id="item-name-autocomplete"
                        value={clearSearch && ""}
                        onChange={handleChangeItemName}
                        options={searchOptions}
                        getOptionLabel={(option) => option?.element?.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="search-name"
                            name="search-name"
                            label={t("orders.add-item-name")}
                            value={typingValue !== "" ? typingValue : ""}
                            onChange={handleChangeTyping}
                            className={classes.fieldClass}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />

                      <div className={classes.viewProduct}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenViewItem}
                          disabled={currentItem === undefined}
                        >
                          {t("inventoryRequests.view-item")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* Buttons for creating and cleaning request */}
          <div className={classes.containerButtons}>
            <Paper elevation={3}>
              <div className={classes.buttons}>
                {(vendorsListBySeller?.length ?? 0) > 1 && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="vendor-select-label">
                      {t("inventoryRequests.select-vendor")}
                    </InputLabel>
                    <Select
                      labelId="vendor-select-label"
                      id="vendor-select"
                      value={selectedVendor}
                      onChange={handleChangeVendor}
                      label={t("inventoryRequests.select-vendor")}
                    >
                      {vendorsList.map((vendor) => (
                        <MenuItem key={vendor.id} value={vendor.id}>
                          {vendor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {vendorError && (
                  <Typography variant="caption" color="error">
                    {t("inventoryRequests.vendor-already-in-order")}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={registerRequest}
                  disabled={
                    itemsAdded?.length === 0 ||
                    itemsAdded === undefined ||
                    vendorError ||
                    selectedVendor === 0
                  }
                >
                  {t("inventoryRequests.create-request")}
                </Button>
                <Button
                  variant="contained"
                  onClick={cleanRequest}
                  disabled={
                    itemsAdded?.length === undefined || itemsAdded?.length === 0
                  }
                >
                  {t("inventoryRequests.clean-request")}
                </Button>
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.boxBottom}>
                <Grid container spacing={3}>
                  {!isSuperAdminOrSuperSeller() && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" color="primary">
                        {t("inventoryRequests.suggested-items")}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.suggestedItemsContainer}
                      >
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableHeaderCell}>
                                {t("inventoryRequests.item")}
                              </TableCell>
                              {/* Mapeamos los vendors de la lista filtrada */}
                              {filteredVendorsList?.map((vendor) => (
                                <TableCell
                                  key={vendor.id}
                                  className={classes.tableHeaderCell}
                                >
                                  {vendor.name}
                                </TableCell>
                              ))}
                              <TableCell
                                className={classes.tableHeaderCell}
                              ></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {suggestedItems?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableCell}
                                >
                                  {item.name}
                                </TableCell>

                                {/* Aquí aseguramos que todas las tiendas estén representadas */}
                                {filteredVendorsList?.map((vendor) => {
                                  const totalQuantity = item.vendors
                                  .filter((v: any) => v.vendorId === vendor.id)
                                  .reduce((sum: number, v: any) => sum + v.quantity, 0);
                                return (
                                  <TableCell key={vendor.id} className={classes.tableCell}>
                                    {totalQuantity}
                                  </TableCell>
                                );
                              })}

                                <TableCell className={classes.tableCell}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleViewSuggestedItem(item)
                                    }
                                  >
                                    <Add />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                  <SummaryRequest
                    itemsAdded={itemsAdded ?? []}
                    handleEditItem={handleEditItem}
                    handleDeleteItem={handleDeleteItem}
                    isAdminOrSuperSeller={isSuperAdminOrSuperSeller() || false}
                  />
                </Grid>
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <ViewItemModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        itemCode={currentItem?.code || ""}
        selectedVendor={selectedVendor}
        itemQuantities={itemQuantities}
        quantityErrors={quantityErrors}
        handleChangeItemQuantity={handleChangeItemQuantity}
        addItemToCart={addItemToCart}
        itemsAdded={itemsAdded || []}
        currentItem={currentItem}
      />
    </Fragment>
  )
}

export default CreateInventoryRequest
