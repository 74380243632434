import { Store } from "redux"

/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const INVENTORY_CROSS_LOADING = "INVENTORY_CROSS_LOADING"
export const INVENTORY_CROSS_FAIL = "INVENTORY_CROSS_FAIL"
export const INVENTORY_CROSS_LIST = "INVENTORY_CROSS_LIST"
export const INVENTORY_CROSS_SET_FILTER = "INVENTORY_CROSS_SET_FILTER"
export const INVENTORY_CROSS_REFRESH = "INVENTORY_CROSS_REFRESH"
export const UPDATE_INVENTORY_CROSS = "UPDATE_INVENTORY_CROSS"
export const INVENTORY_CROSS_SUCCESS = "INVENTORY_CROSS_SUCCESS"
export const INVENTORY_CROSS_REVOKE = "INVENTORY_CROSS_REVOKE"
export const REVOKE_REFRESH = "REVOKE_REFRESH"
export const INVENTORY_STORES_FOR_ITEM_LIST = "INVENTORY_STORES_FOR_ITEM_LIST"
export const INVENTORY_ITEMS_FOR_REVIEW = "INVENTORY_ITEMS_FOR_REVIEW"
/**
 *****************************************
 *********** Data Types ******************
 *****************************************
 */
export interface InventoryItem {
  itemId: number
  itemName: string
  imageURL: string
  currentStock: number
}

export interface RevokedItem {
  id: number
  itemName: string
  vendorName: string,
  quantity: number | null
  digitalQuantity: number | null
  creationDate: string
}

export interface StoreItem {
  id: number
  name: string
}

export interface RequestsItem {
  id: number
  itemName: string
  systemQuantity: number
  sellerQuantity: number
  proccesQuantity: number
  differenceQuantity: number
  sellerName: string
  vendorName: string
}
export interface SortInfo {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

export interface Pageable {
  sort: SortInfo
  offset: number
  pageNumber: number
  pageSize: number
  unpaged: boolean
  paged: boolean
}

export interface InventoryCrossData {
  data: {
    content: InventoryItem[]
    pageable: Pageable
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    size: number
    sort: SortInfo
    numberOfElements: number
    first: boolean
    empty: boolean
  }
}

export interface ItemReviewEntryResponse {
  status_code: number
  status: string
  message: string
  data: string
}

export interface InventoryCrossRevokedData {
  data: {
    content: RevokedItem[]
    pageable: Pageable
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    size: number
    sort: SortInfo
    numberOfElements: number
    first: boolean
    empty: boolean
  }
}

export interface StoresForItemData {
  data:{
    content: StoreItem[]
    pageable: Pageable
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    size: number
    sort: SortInfo
    numberOfElements: number
    first: boolean
    empty: boolean
  }
}

export interface ItemsReviewEntriesForRevisionData {
  data: {
    content: RequestsItem[]
    pageable: Pageable
    last: boolean
    totalPages: number
    totalElements: number
    number: number
    size: number
    sort: SortInfo
    numberOfElements: number
    first: boolean
    empty: boolean
  }
}

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */

export interface InventoryCrossLoading {
  type: typeof INVENTORY_CROSS_LOADING
  payload: string
}

export interface InventoryCrossFail {
  type: typeof INVENTORY_CROSS_FAIL
  payload: string
}
export interface InventoryCrossListType {
  type: typeof INVENTORY_CROSS_LIST
  payload: InventoryCrossData
}

export interface InventoryCrossUpdate {
  type: typeof UPDATE_INVENTORY_CROSS
  payload: string
}

export interface InventoryCrossRefresh {
  type: typeof INVENTORY_CROSS_REFRESH
  payload: boolean
}

export interface InventoryCrossSuccess {
  type: typeof INVENTORY_CROSS_SUCCESS
  payload: string
}

export interface InventoryCrossRevoke {
  type: typeof INVENTORY_CROSS_REVOKE
  payload: InventoryCrossRevokedData
}

export interface RevokeFresh {
  type: typeof REVOKE_REFRESH
  payload: boolean
}

export interface InventoryStoresForItemList {
  type: typeof INVENTORY_STORES_FOR_ITEM_LIST 
  payload: StoresForItemData
}

export interface InventoryItemsForReview {
  type: typeof INVENTORY_ITEMS_FOR_REVIEW
  payload: ItemsReviewEntriesForRevisionData
}
// Export types.
export type InventoryCrossDispatchTypes =
  | InventoryCrossLoading
  | InventoryCrossFail
  | InventoryCrossListType
  | InventoryCrossUpdate
  | InventoryCrossRefresh
  | InventoryCrossSuccess
  | InventoryCrossRevoke
  | RevokeFresh
  | InventoryStoresForItemList
  | InventoryItemsForReview