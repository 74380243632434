import axios from "axios"
import { Dispatch } from "redux"
import {
  INVENTORY_CROSS_FAIL,
  INVENTORY_CROSS_LIST,
  INVENTORY_CROSS_LOADING,
  INVENTORY_CROSS_REVOKE,
  INVENTORY_CROSS_SUCCESS,
  INVENTORY_ITEMS_FOR_REVIEW,
  INVENTORY_STORES_FOR_ITEM_LIST,
  InventoryCrossData,
  InventoryCrossDispatchTypes,
  InventoryCrossRevokedData,
  ItemsReviewEntriesForRevisionData,
  RequestsItem,
  REVOKE_REFRESH,
  StoresForItemData,
} from "./InventoryCrossActionsTypes"

/**
 * Get a custom list of items for revision, filtering by vendorId, itemType, searchTerm, and availability.
 * @param {object} qParams - Query parameters for filtering the items.
 */
export const getItemsForRevision =
  (qParams: object) =>
  async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token
    const apiURL: string = process.env.REACT_APP_API_URL || ""

    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({
          type: INVENTORY_CROSS_LOADING,
          payload: "LIST",
        })

        const headers: object = {
          ContentType: "application/json",
          Authorization: "Bearer " + userToken,
        }

        const res = await axios.get<InventoryCrossData>(
          `${apiURL}/seller/getItemsForRevision`,
          {
            params: qParams,
            headers: headers,
          }
        )
        if (res.status === 200) {
          dispatch({
            type: INVENTORY_CROSS_LIST,
            payload: res.data,
          })
        } else {
          dispatch({
            type: INVENTORY_CROSS_FAIL,
            payload: "status.get-error",
          })
        }
      } catch (error) {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } else {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  }

/**
 * Create a new item review entry for a specific item.
 * @param {number} vendorId - The vendorId of the item.
 * @param {number} itemId - The itemId of the item.
 * @param {number} quantity - The quantity of the item.
 * @returns {void}
 */
export const createItemReviewEntry =
  (vendorId: number, itemId: number, quantity: number) =>
  async (dispatch: Dispatch) => {
    const userToken: string | undefined = localStorage.token
    const apiURL: string = process.env.REACT_APP_API_URL || ""

    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({ type: INVENTORY_CROSS_LOADING, payload: "LIST" })

        const headers = {
          ContentType: "application/json",
          Authorization: `Bearer ${userToken}`,
        }

        const body = {
          vendorId,
          itemId,
          quantity,
        }

        const res = await axios.post(
          `${apiURL}/seller/createItemReviewEntry`,
          body,
          { headers }
        )

        if (res.status === 200) {
          dispatch({
            type: INVENTORY_CROSS_SUCCESS,
            payload: "status.success-create",
          })
        } else {
          dispatch({
            type: INVENTORY_CROSS_FAIL,
            payload: "status.create-error",
          })
        }
      } catch (error) {
        const errorMessage = (error as any).message || "status.create-error"
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: errorMessage,
        })
      }
    } else {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.create-error",
      })
    }
  }

export const revokeItemReviewEntry =
  (qParams: object) =>
  async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token
    const apiURL: string = process.env.REACT_APP_API_URL || ""

    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({
          type: INVENTORY_CROSS_LOADING,
          payload: "LIST",
        })

        const headers: object = {
          ContentType: "application/json",
          Authorization: "Bearer " + userToken,
        }

        const res = await axios.get<InventoryCrossRevokedData>(
          `${apiURL}/seller/getRevokedItemReviewEntries`,
          {
            params: qParams,
            headers: headers,
          }
        )
        if (res.status === 200) {
          dispatch({
            type: INVENTORY_CROSS_REVOKE,
            payload: res.data,
          })
        } else {
          dispatch({
            type: INVENTORY_CROSS_FAIL,
            payload: "status.get-error",
          })
        }
      } catch (error) {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } else {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  }

export const updateItemReviewEntry =
  (qParams: object) =>
  async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token
    const apiURL: string = process.env.REACT_APP_API_URL || ""

    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({
          type: INVENTORY_CROSS_LOADING,
          payload: "LIST",
        })

        const headers: object = {
          ContentType: "application/json",
          Authorization: "Bearer " + userToken,
        }

        const res = await axios.put(
          `${apiURL}/seller/updateItemReviewEntry`,
          qParams,
          { headers }
        )
        if (res.status === 200) {
          dispatch({
            type: INVENTORY_CROSS_SUCCESS,
            payload: "status.success-update",
          })
        } else {
          dispatch({
            type: INVENTORY_CROSS_FAIL,
            payload: "status.get-error",
          })
        }
      } catch (error) {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } else {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  }

/**
 * Update the refresh items view state.
 * @param {boolean} value
 */
export const refreshView =
  (value: boolean) =>
  async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
    dispatch({
      type: REVOKE_REFRESH,
      payload: value,
    })
  }

  
export const getStoresForItemReview =
() =>
async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token
  const apiURL: string = process.env.REACT_APP_API_URL || ""

  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: INVENTORY_CROSS_LOADING,
        payload: "LIST",
      })

      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + userToken,
      }

      const res = await axios.get<StoresForItemData>(
        `${apiURL}/seller/getStoresForItemReview`,
        {
          headers: headers,
        }
      )
      if (res.status === 200) {
        dispatch({
          type: INVENTORY_STORES_FOR_ITEM_LIST,
          payload: res.data,
        })
      } else {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } catch (error) {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  } else {
    dispatch({
      type: INVENTORY_CROSS_FAIL,
      payload: "status.get-error",
    })
  }
}

export const getItemsReviewEntriesForRevision =
(qParams: object | null) =>
async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token
  const apiURL: string = process.env.REACT_APP_API_URL || ""

  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: INVENTORY_CROSS_LOADING,
        payload: "LIST",
      })

      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + userToken,
      }

      const res = await axios.get<ItemsReviewEntriesForRevisionData>(
        `${apiURL}/admin/getItemsReviewEntriesForRevision`,
        {
          headers: headers,
          params: qParams,
        }
      )
      if (res.status === 200) {
        dispatch({
          type: INVENTORY_ITEMS_FOR_REVIEW,
          payload: res.data,
        })
      } else {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } catch (error) {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  } else {
    dispatch({
      type: INVENTORY_CROSS_FAIL,
      payload: "status.get-error",
    })
  }
}


export const adminUpdateItemReviewEntry =
  (qParams: object) =>
  async (dispatch: Dispatch<InventoryCrossDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token
    const apiURL: string = process.env.REACT_APP_API_URL || ""

    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({
          type: INVENTORY_CROSS_LOADING,
          payload: "LIST",
        })

        const headers: object = {
          ContentType: "application/json",
          Authorization: "Bearer " + userToken,
        }

        const res = await axios.put(
          `${apiURL}/admin/updateItemReviewEntry`,
          qParams,
          { headers }
        )
        if (res.status === 200) {
          dispatch({
            type: INVENTORY_CROSS_SUCCESS,
            payload: "status.success-update",
          })
        } else {
          dispatch({
            type: INVENTORY_CROSS_FAIL,
            payload: "status.get-error",
          })
        }
      } catch (error) {
        dispatch({
          type: INVENTORY_CROSS_FAIL,
          payload: "status.get-error",
        })
      }
    } else {
      dispatch({
        type: INVENTORY_CROSS_FAIL,
        payload: "status.get-error",
      })
    }
  }