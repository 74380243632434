// React.
import React, { Fragment, useEffect, useState } from "react"

// Components.
import Pagination from "../../admin/utils/ResultsPagination"

// Material UI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../Store"

// Global config.

// Moment.

// Translations.
import { useTranslation } from "react-i18next"

// Styles.
import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Modal,
  Paper,
} from "@material-ui/core"
import Link from "@material-ui/core/Link"
import jwtDecode from "jwt-decode"
import moment from "moment"
import { refreshDeliveryView } from "../../../actions/deliveryActions/DeliveryActions"
import {
  getInventoryRequests,
  updateInventoryRequest,
} from "../../../actions/inventoryRequestsActions/InventoryRequestsActions"
import { getAllVendorBySeller } from "../../../actions/vendorActions/VendorActions"
import { useStyles } from "../../client/deliveries/DeliveryAccordionStyles"

interface InventoryRequestAccordionProps {
  filterTab: number
  pageByTab: { [key: number]: number }
}

const InventoryRequestAccordion: React.FC<InventoryRequestAccordionProps> = ({
  filterTab,
  pageByTab,
}) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const decodedToken: any =
    localStorage.token !== undefined && localStorage.token !== ""
      ? jwtDecode(localStorage.token)
      : false
  const url = window.location.href
  const [currentLoading, setCurrentLoading] = useState<string>("")
  const isAdminSingles = decodedToken
    ? decodedToken.auth.includes("ROLE_ADMIN_SINGLES")
    : false
  const isAdmin = decodedToken
    ? decodedToken.auth.includes("ROLE_ADMIN")
    : false
  const actionStatus = useSelector(
    (state: RootStore) => state.order.actionStatus
  )
  // Redux state.
  const inventoryRequestsState = useSelector(
    (state: RootStore) => state.inventoryRequests
  )
  const vendorState = useSelector((state: RootStore) => state.vendors)
  const vendorsListBySeller = vendorState.vendorsBySeller
  const {
    inventoryRequestsList,
    refresh,
    inventoryRequestsFilters: filters,
  } = inventoryRequestsState

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    dispatch(getAllVendorBySeller())
    // eslint-disable-next-line
  }, [])

  // Lógica principal con dependencias
  useEffect(() => {
    // Asegúrate de que vendorsListBySeller tenga datos antes de continuar
    if (!vendorsListBySeller || vendorsListBySeller.length === 0) {
      return // Sal de este efecto si no hay datos
    }

    const params = new URLSearchParams(window.location.search)
    let page = params.get("page")
    const pNumber = Number(page) || 1
    const currentPage = Number(page) || 1

    const vendorId = vendorsListBySeller[0]?.vendorId // Ahora seguro de que existe

    // Actualizar el estado con la página actual para el tab seleccionado
    setCurrentPage(pageByTab[filterTab] || currentPage)

    const qParams: {
      page: number
      size: string
      pageSize: number
      paged: boolean
      originVendorId?: number
      pageNumber: number
      destinationVendorId?: number
    } = {
      ...filters,
      page: page ? pNumber - 1 : 0,
      size: "10",
      pageSize: 10,
      pageNumber: 1,
      paged: true,
    }


    // Agregar originVendorId o destinationsVendorId basado en filterTab
    if (filterTab === 0 && !isAdmin) {
      qParams.originVendorId = vendorId
    } else if (filterTab === 1 && !isAdmin) {
      qParams.destinationVendorId = vendorId
    }

    setCurrentLoading("")
    dispatch(getInventoryRequests(qParams))
    setCurrentPage(page ? pNumber : 1)
    scrollToTop()
    dispatch(refreshDeliveryView(false))
    // eslint-disable-next-line
  }, [vendorsListBySeller, url, refresh, filterTab, pageByTab])

  useEffect(() => {
    if (
      actionStatus?.status === "success" ||
      actionStatus?.status === "error"
    ) {
      setCurrentLoading("")
      const params = new URLSearchParams(window.location.search)
      let page = params.get("page")
      const pNumber = Number(page)
      const currentPage = Number(page) || 1

      setCurrentPage(pageByTab[filterTab] || currentPage)

      const qParams: any = {
        page: page ? pNumber - 1 : 0,
        size: 10,
        paged: true,
      }

      if (filterTab === 0 && !isAdmin) {
        qParams.originVendorId = vendorsListBySeller?.[0]?.vendorId // Replace with actual originVendorId
      } else if (filterTab === 1 && !isAdmin) {
        qParams.destinationsVendorId = vendorsListBySeller?.[0]?.vendorId // Replace with actual destinationsVendorId
      }

      dispatch(getInventoryRequests(qParams))
      setCurrentPage(page ? pNumber : 1)

      dispatch(refreshDeliveryView(false))
    }
    // eslint-disable-next-line
  }, [actionStatus])

  const handleCardActionClick = async (
    event: React.MouseEvent,
    oId: number,
    oNewStatus: string
  ) => {
    event.stopPropagation()
    if (oId && oNewStatus) {
      setCurrentLoading(`${oNewStatus}-${oId}`)
      try {
        await new Promise((resolve) => setTimeout(resolve, 1)) // Timeout de 1 milisegundo
        await dispatch(updateInventoryRequest(oId, oNewStatus))
      } finally {
        setCurrentLoading("")
      }
    }
  }

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedRequest, setSelectedRequest] = useState<any>(null)

  const handleOpenModal = (request: any) => {
    setSelectedRequest(request)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedRequest(null)
  }

  const isDiabledCard = (cStatus: string, oStatus: string) => {
    const orderStatus = oStatus.toLocaleLowerCase()
    if (filterTab === 0 && cStatus === "completado" && !isAdmin) {
      return true
    }
    if (filterTab === 1 && cStatus === "en transito" && !isAdmin) {
      return true
    }
    if (cStatus === "procesando" && orderStatus === "procesando") {
      return true
    }
    if (cStatus === "en transito" && orderStatus === "procesando") {
      return false
    }
    if (cStatus === "completado" && orderStatus === "en transito") {
      return false
    }
    if (
      cStatus === "cancelado" &&
      orderStatus !== "cancelado" &&
      orderStatus !== "completado" &&
      orderStatus !== "en transito"
    ) {
      return false
    }

    return true
  }

  const isCurrentStatus = (cStatus: string, oStatus: string) => {
    const orderStatus = oStatus.toLocaleLowerCase()
    if (cStatus === orderStatus) {
      return true
    }
    return false
  }

  const calculateTotalItems = (request: any) => {
    return request.internalOrderHasItems.reduce((total: number, item: any) => {
      return total + item.normalQuantity
    }, 0)
  }

  return (
    <Fragment>
      {inventoryRequestsList?.content !== undefined &&
        inventoryRequestsList!.content!.map((request) => (
          <Fragment key={request.id}>
            <Divider />
            <Grid container spacing={2} className={classes.orderRow}>
              <Grid item xs={12} sm={12} md={4}>
                <>
                  <Typography variant="caption" color="textSecondary">
                    {t("inventoryRequests.ID")} {request.id}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("inventoryRequests.from-store")}{" "}
                    {request.originVendor.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("inventoryRequests.to-store")}{" "}
                    {request.destinationVendor.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("inventoryRequests.total-items")}{" "}
                    {calculateTotalItems(request)}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("inventoryRequests.author")} {request.user.email}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t("inventoryRequests.created-date")}{" "}
                    {moment(request.creationDate).format("DD-MM-YYYY HH:mm:ss")}
                  </Typography>
                  <Link
                    component="button"
                    color="primary"
                    onClick={() => handleOpenModal(request)}
                  >
                    {t("inventoryRequests.view-details")}
                  </Link>
                </>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Card
                  className={`
                    ${
                      isDiabledCard("procesando", request.status)
                        ? classes.disabledCard
                        : ""
                    } 
                ${
                  isCurrentStatus("procesando", request.status)
                    ? classes.currentStatus
                    : ""
                }`}
                >
                  <CardActionArea
                    disabled={
                      isDiabledCard("procesando", request.status) ||
                      currentLoading === `Procesando-${request.id}`
                    }
                  >
                    <CardContent>
                      <Typography className={classes.ctaTitle}>
                        {!isDiabledCard("procesando", request.status) && (
                          <span>
                            {t("orders.move-to")}
                            <br />
                          </span>
                        )}
                        Procesando
                      </Typography>
                      {currentLoading === `Procesando-${request.id}` && (
                        <Typography variant="caption">
                          {t("orders.processing")}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Card
                  className={`${
                    isDiabledCard("en transito", request.status)
                      ? classes.disabledCard
                      : ""
                  }
                 ${
                   isCurrentStatus("en transito", request.status)
                     ? classes.currentStatus
                     : ""
                 }`}
                >
                  <CardActionArea
                    disabled={
                      isDiabledCard("en transito", request.status) ||
                      currentLoading === `En Transito-${request.id}`
                    }
                    onClick={(event) =>
                      handleCardActionClick(event, request.id, "En Transito")
                    }
                  >
                    <CardContent>
                      <Typography className={classes.ctaTitle}>
                        {!isDiabledCard("en transito", request.status) && (
                          <span>
                            {t("orders.move-to")}
                            <br />
                          </span>
                        )}
                        En Tránsito
                      </Typography>
                      {currentLoading === `En Transito-${request.id}` && (
                        <Typography variant="caption">
                          {t("orders.processing")}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <Card
                  className={`${
                    isDiabledCard("completado", request.status) ||
                    isAdminSingles
                      ? classes.disabledCard
                      : ""
                  } 
                ${
                  isCurrentStatus("completado", request.status)
                    ? classes.currentStatus
                    : ""
                }`}
                >
                  <CardActionArea
                    disabled={
                      isDiabledCard("completado", request.status) ||
                      currentLoading === `Completado-${request.id}` ||
                      isAdminSingles
                    }
                    onClick={(event) =>
                      handleCardActionClick(event, request.id, "Completado")
                    }
                  >
                    <CardContent>
                      <Typography className={classes.ctaTitle}>
                        {!isDiabledCard("completado", request.status) && (
                          <span>
                            {t("orders.move-to")}
                            <br />
                          </span>
                        )}
                        Completado
                      </Typography>

                      {currentLoading === `Completado-${request.id}` && (
                        <Typography variant="caption">
                          {t("orders.processing")}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {isAdmin && (
                <Grid item xs={6} sm={4} md={2}>
                  <Card
                    className={`${
                      isDiabledCard("cancelado", request.status)
                        ? classes.disabledCard
                        : ""
                    } 
                    ${
                      isCurrentStatus("cancelado", request.status)
                        ? classes.currentStatus
                        : ""
                    }`}
                  >
                    <CardActionArea
                      disabled={
                        isDiabledCard("cancelado", request.status) ||
                        currentLoading === `Cancelado-${request.id}`
                      }
                      onClick={(event) =>
                        handleCardActionClick(event, request.id, "Cancelado")
                      }
                    >
                      <CardContent>
                        <Typography className={classes.ctaTitle}>
                          {!isDiabledCard("cancelado", request.status) && (
                            <span>
                              {t("orders.move-to")}
                              <br />
                            </span>
                          )}
                          Cancelado
                        </Typography>

                        {currentLoading === `Cancelado-${request.id}` && (
                          <Typography variant="caption">
                            {t("orders.processing")}
                          </Typography>
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Fragment>
        ))}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Paper className={classes.modalContent}>
          {selectedRequest && (
            <Fragment>
              <Typography variant="h6" style={{ paddingBottom: "8px" }}>
                {t("inventoryRequests.details")}
              </Typography>
              {selectedRequest.internalOrderHasItems &&
                selectedRequest.internalOrderHasItems.map((item: any) => (
                  <>
                    <Divider key={item.id} />
                    <div className={classes.itemContainer}>
                      <img
                        src={item.item.images[0].url}
                        alt={item.name}
                        className={classes.itemImage}
                      />
                      <div className={classes.detailsModal}>
                        <Typography variant="body1">
                          {t("inventoryRequests.ID")} {item.item.id}
                        </Typography>
                        <Typography variant="body1">
                          {t("inventoryRequests.item-name")} {item.item.name}
                        </Typography>
                        <Typography variant="body1">
                          {t("inventoryRequests.item-type")}{" "}
                          {item.item.itemType.name}
                        </Typography>
                        <Typography variant="body1">
                          {t("inventoryRequests.item-quantity")}{" "}
                          {item.normalQuantity}
                        </Typography>
                      </div>
                    </div>
                  </>
                ))}
            </Fragment>
          )}
        </Paper>
      </Modal>
      {inventoryRequestsList?.totalPages !== undefined &&
      inventoryRequestsList?.totalPages !== 1 ? (
        <Pagination
          current={currentPage}
          path="/admin/inventory-requests"
          pagesNumber={inventoryRequestsList!.totalPages}
        />
      ) : (
        ""
      )}
    </Fragment>
  )
}

export default InventoryRequestAccordion
